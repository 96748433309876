import React,{ useState } from 'react'
import './App.css'
import Spline from '@splinetool/react-spline';
import projects from './components/projectsData';
import {FaPhone, FaEnvelope, FaLinkedin, FaMailBulk} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const [isOpen, setIsOpen] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState({});
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://chethakalakshitha.xyz/api/submit-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data);
            toast.success("Message sent successfully!");
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });
        } else {
            toast.error("Failed to send message!");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setActiveImageIndex(0);
        setModalOpen(true);
    };
  return (
      <div className='container'>
          <ToastContainer position="top-center" />
          <div className='landing-spline'>
              <Spline scene="https://prod.spline.design/V9ubfrFMNzrRAIoS/scene.splinecode"/>
          </div>
          {/*    About   */}
          <section className='about-section'>
              <div className='about-container'>
              <div className='details'>
                  <h1 className='titles'>About</h1>
                  <p className="about-text">Hey there, I'm Chethaka Lakshitha, a software engineering enthusiast who's been captivated by the
                      world of code since the tender age of 16. Crafting something from scratch and seeing it come to
                      life is pure bliss for me. As a MERN Stack expert, I've honed my craft over years, developing
                      websites and mobile applications using cutting-edge technologies like React and React Native. But
                      my expertise doesn't stop there—I thrive on challenges and relish diving into new technologies
                      headfirst. With over four years of experience, I seamlessly blend design and functionality,
                      crafting captivating websites and bringing stunning logos to life.</p>
                  <p className="about-text">Coding isn't just a career for me—it's my passion, my therapy, my everything. It's what drives me
                      to connect with talented individuals and businesses worldwide, transforming their visions into
                      reality. Collaborating with diverse teams, I thrive on the thrill of problem-solving, refusing to
                      rest until I've cracked the code and delivered top-notch solutions. I believe in offering quality
                      service at an affordable price, prioritizing your satisfaction above all else. Need revisions? No
                      problem. I'm here to work alongside you until we've crafted something truly extraordinary. Ready
                      to turn your ideas into reality? Let's connect and create something amazing together.</p>
              </div>
              <div className='about-image'>
                  <img src="techstack.png" width='100%' height='100%'/>
              </div>
          </div>
          </section>
          {/* Projects Section */}
          <section className='projects-section'>
              <h1 className='section-title'>Projects</h1>
              <div className='projects-grid'>
                  {projects.map(project => (
                      <div className='project-card' key={project.name} onClick={() => handleProjectClick(project)}>
                          <img src={project.img} alt={project.name}/>
                          <div className='project-info'>
                              <h3>{project.name}</h3>
                              <p>{project.description}</p>
                          </div>
                      </div>
                  ))}
              </div>
          </section>
          {/* Modal */}
          {modalOpen && (
              <div className="modal">
                  <div className="modal-content">
                      <div className="image-container">
                          {selectedProject.images.map((image, index) => (
                              <img key={index} src={image} alt="Selected Project" className="modal-image"/>
                          ))}
                      </div>
                      <h2 className="modal-title">{selectedProject.name} - Project Scenario</h2>
                      <p className="modal-description">{selectedProject.scope}</p>
                      <a className="modal-description" href={selectedProject.link}>{selectedProject.link ? `Link: ${selectedProject.name}`:""}</a>
                      <p className="modal-description">{selectedProject.stack}</p>
                      <div className="close" onClick={() => setModalOpen(false)}>CLOSE</div>
                  </div>
              </div>
          )}
          {/*Contact Us*/}
          <section className="contact-section">
              <h2 className='contact-title'>Contact Me</h2>
              <div className="contact-ways">
                  <div className="contact-item">
                      <FaMailBulk size={24}/>
                      <p className="contact-item-text">chethaka.sl@gmail.com</p>
                  </div>
                  <div className="contact-item">
                      <FaPhone size={24}/>
                      <p className="contact-item-text">+94712176827</p>
                  </div>
                  <div className="contact-item">
                      <FaLinkedin size={24}/>
                      <a href="https://www.linkedin.com/in/chethaka-lakshitha-6b3534113/" className="contact-item-text">Chethaka Lakshitha</a>
                  </div>
              </div>
              <div className="center-section">
                  <div className="contact-glass-card">
                      {/*name*/}
                      <label>
                          Full Name
                      </label>
                      <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          placeholder="Full Name"
                          required
                      />
                      {/*  email  */}
                      <label>
                          Email
                      </label>
                      <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          placeholder="Email"
                          required
                      />
                      {/*subject*/}
                      <label>
                          Subject
                      </label>
                      <input
                          type="text"
                          name="subject"
                          value={formData.subject}
                          onChange={handleInputChange}
                          placeholder="Subject"
                          required
                      />
                      {/*  message  */}
                      <label>
                          Message
                      </label>
                      <textarea
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          placeholder="Message"
                          required>
                      </textarea>
                      <center>
                          <button onClick={handleSubmit}>SEND</button>
                      </center>
                  </div>
              </div>
          </section>
      </div>
  );
}

export default App;


// projectsData.js
const projects = [
    {
        name: "Aurelio SRL Cargo App",
        img: "AurCargo.png",
        images: ["mobile/cargo-splash.png","mobile/cargo-login.png","mobile/cargo-home.png","mobile/cargo-qr.png","mobile/cargo-invoice-create.png","mobile/cargo-invoice.png"],
        description: "A logistics app to streamline cargo deliveries.",
        scope: "The Aurelio SRL Cargo App is a specialized React Native application designed to streamline logistics operations specifically for Aurelio SRL, a company operating between Italy and Sri Lanka. The application's primary objective is to facilitate quick and easy invoice management for employees, integrating features such as QR code scanning to retrieve invoice data from a MongoDB database. Users will be able to create, download, and print invoices directly from the application, with support for Bluetooth printers to enhance operational efficiency in real-time courier service environments. The app aims to be highly responsive and crash-resistant, ensuring reliable performance under the fast-paced conditions typical in courier and shipping operations. The development timeline is set for two weeks with a budget constraint of $150, emphasizing rapid deployment and robust app functionality to meet the urgent needs of the logistic operations.",
        stack: "React Native, NodeJs, MongoDB"
    },
    {
        name: "Aurelio SRL Planteria App",
        img: "Planteria.png",
        images: ["mobile/planteria-splash.png","mobile/planteria-login.png","mobile/planteria-home.png","mobile/planteria-qr.png","mobile/planteria-result.png"],
        description: "Plant care and shop application.",
        scope: "The Aurelio SRL Cargo App is a specialized React Native application designed to streamline logistics operations specifically for Aurelio SRL, a company operating between Italy and Sri Lanka. The application's primary objective is to facilitate quick and easy invoice management for employees, integrating features such as QR code scanning to retrieve invoice data from a MongoDB database. Users will be able to create, download, and print invoices directly from the application, with support for Bluetooth printers to enhance operational efficiency in real-time courier service environments. The app aims to be highly responsive and crash-resistant, ensuring reliable performance under the fast-paced conditions typical in courier and shipping operations. The development timeline is set for two weeks with a budget constraint of $150, emphasizing rapid deployment and robust app functionality to meet the urgent needs of the logistic operations.",
        stack: "React Native, NodeJs, MongoDB"
    },
    {
        name: "Meditech Mobile App",
        img: "MedTech.png",
        images: ["mobile/medtech-splash.png","mobile/medtech-login.png","mobile/medtech-home.png","mobile/medtech-list.png","mobile/medtech-result.png"],
        description: "Medical appointment and records management.",
        scope: "Meditech seeks to simplify how individuals locate medical facilities and available tests such as blood tests, MRIs, and X-rays through an intuitive mobile application. This React Native app will utilize Node.js and MongoDB to fetch and display data on medical test availability, pricing, and location based on user proximity. The app will feature an integrated search function allowing users to find the nearest medical services tailored to their specific needs. Accurate GPS location fetching and a responsive interface are critical, ensuring users can reliably plan their medical visits. The project is set within a one-week timeframe with a budget of $150, emphasizing rapid development and deployment.",
        stack: "React Native, NodeJs, MongoDB"
    },
    {
        name: "Infigomedia AI Powered Digital Marketing App",
        img: "websites/Infigo Media - 1.png",
        images: ["websites/Infigo Media - 1.png","websites/Infigo Media - 2.png"],
        description: "AI-driven marketing solutions.",
        scope: "Infigomedia AI Powered Digital Marketing App is designed to revolutionize how small businesses manage their digital marketing efforts. This AI-driven tool aims to centralize various marketing functionalities including a unified inbox for major social platforms, an intelligent AI chatbot for customer service, and a content management system that allows simultaneous posting across linked social media accounts. Additional features include inventory management and an integrated e-commerce platform, supported by AI technologies such as TensorFlow and Wit.ai for advanced data analysis and automated response generation. The project spans six months and involves a sophisticated blend of technologies to provide a comprehensive, scalable solution for digital marketing needs.",
        stack: "React Native, NodeJs, MongoDB, React, Python(Flask), Wit.ai, TensorFlow"
    },
    {
        name: "Aurelio SRL Website",
        img: "websites/AurelioSRL-2.png",
        images: ["websites/AurelioSRL-1.png","websites/AurelioSRL-2.png"],
        description: "Corporate website for Aurelio SRL.",
        scope: "The Aurelio SRL Website is designed as a robust informational platform using WordPress to highlight and detail the diverse services offered by Aurelio SRL. Targeting potential customers interested in food, clothing, export items, courier, and cleaning services, the website will serve as a digital brochure with high operational performance to ensure user engagement and seamless navigation. The scope involves developing an aesthetically pleasing interface that aligns with the company’s brand identity, ensuring that each service is appropriately showcased to enhance consumer perception and drive business engagements. The website development is planned for completion within one week, with a budget of $150, prioritizing efficiency and effectiveness in delivery.",
        stack: "Wordpress",
        link: "https://www.aureliosrl.it"
    },
    {
        name: "RopeAccess Website",
        img: "websites/RopeAccess - 1.png",
        images: ["websites/RopeAccess - 1.png","websites/RopeAccess - 2.png"],
        description: "Website for a rope access service company.",
        scope: "RopeAccess Website aims to establish a strong online presence for a novel company specializing in reconstruction and maintenance services. Using WordPress, the website will detail the company's innovative approaches and experienced team capabilities. The site will include sections for services offered, case studies, testimonials, and contact information to facilitate engagement from potential clients needing high-quality, reliable cleaning, reconstruction, and maintenance services. The focus will be on high-performance technical solutions to ensure the website remains responsive and accessible, reflecting the agility and confidence of the RopeAccess team in a competitive market.",
        stack: "Wordpress",
        link: "https://www.ropeaccess.lk",
    },
    {
        name: "E-Commerce Website",
        img: "websites/E-Commerce - 1.png",
        images: ["websites/E-Commerce - 1.png","websites/E-Commerce - 1.png"],
        description: "Website for a online clothing shop",
        scope:"The goal for the E-commerce Website is to develop a comprehensive online store for a clothing retailer, complete with a robust admin panel and an inventory management system. This platform, built using React, NodeJs, and MongoDB, will provide a seamless shopping experience for customers and efficient backend operations for the store owners. Key features include user review functionalities, sales analysis tools, and a responsive design that adapts to various devices ensuring optimal display and functionality. The system will support real-time inventory updates, allowing for accurate stock management and minimizing the risk of overselling items. The development process is planned to span two weeks with a budget of $250, focusing on creating an intuitive user interface and a reliable, scalable backend that supports growth and complex data needs.",
        stack: "React, NodeJs, MongoDB",
    },
    {
        name: "Friend's Module Website",
        img: "websites/FriendsModule.png",
        images: ["websites/FriendsModule.png"],
        description: "Website for a online clothing shop",
        scope:"Friend's Module Website introduces a unique social media platform centered around audio messaging. This project will leverage React and NodeJs with a PostgreSQL database to create a dynamic environment where users can post, share, and interact primarily through audio clips. Additional functionalities will include the ability to attach descriptions and images to these audio posts, enhancing the contextual richness of communications. The interface will prioritize user engagement and interactivity, with a focus on performance and responsiveness to accommodate high volumes of concurrent interactions. The development timeline is set for three weeks with a budget of $250, ensuring the platform is robust enough to handle media-rich content and user interactions efficiently.",
        stack: "React, NodeJs, PostgreSQL",
        link: "https://www.friendsmodule.com"
    }
];

export default projects;
